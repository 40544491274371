<template>
    <div class="billingDetailClass">
        <section class="centerDetail" style="margin-top:51px">
            <!-- 返回按钮 -->
            <div @click="returnBack()" class="returnBtn">
                <img src="@/assets/images/storeManage/left_arrow.png" alt/>
                <span>返回</span>
            </div>
            <!-- 店铺名称 -->
            <div class="storeName">
                <span>Micle</span>
                <span class="line">|</span>
                <span>账单明细</span>
            </div>
            <!-- 账单列表 -->
            <div>
                <!-- 未付款账单 -->
                <div style="margin-bottom: 70px;">
                    <div class="titleBorder resetElementClass">
                        <span>未付款账单</span>
                        <el-tooltip class="item resetElementClass" effect="dark" content="Right Top 提示文字"
                                    placement="right-start">
                            <el-button>
                                <i class="el-icon-info"></i>
                            </el-button>
                        </el-tooltip>
                    </div>
                    <div class="">
                        <el-table
                                :data="tableData"
                                border
                                style="width: 100%">
                            <el-table-column
                                    prop="date"
                                    label="周期"
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="项目"
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    label="金额">
                            </el-table-column>
                            <el-table-column
                                    label="">
                                <template slot-scope="scope">
                                      <span class="orangeFont">
                                        <span>付款剩余时间：</span>
                                        <span>03天06时12分</span>
                                      </span>
                                    <span
                                            class="toPay"
                                            size="mini"
                                            type="danger"
                                            @click="handleToPay(scope.$index, scope.row)">去付款</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="paginationBorder">
                        <!--分页-->
                        <el-pagination
                                style="padding-top: 17px;text-align: right;"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage4"
                                :page-sizes="[100, 200, 300, 400]"
                                :page-size="100"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="400">
                        </el-pagination>
                    </div>
                </div>
                <!-- 已付款账单 -->
                <div>
                    <div class="titleBorder">
                        <span>已付款账单</span>
                    </div>
                    <div class="">
                        <el-table
                                :data="tableData"
                                border
                                style="width: 100%">
                            <el-table-column
                                    prop="date"
                                    label="账单号"
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="付款时间"
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    label="购买产品">
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    label="金额">
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    label="支付方式">
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    label="状态">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="paginationBorder">
                        <!--分页-->
                        <el-pagination
                                style="padding-top: 17px;text-align: right;"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage4"
                                :page-sizes="[100, 200, 300, 400]"
                                :page-size="100"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="400">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "billingDetail",
        data() {
            return {
                basisInfoList: [
                    {
                        name: "后台地址",
                        content: "https://funpinpin.com"
                    },
                    {
                        name: "店铺主域名",
                        content: "https://funpinpin.com"
                    },
                    {
                        name: "添加时间",
                        content: "2020年08月13日 16:12"
                    }
                ],
                tableData: [{
                    id: '1',
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    id: '2',
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    id: '3',
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    id: '4',
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }],
                currentPage4: 4,
            };
        },
        methods: {
            handleToPay(i, data) {
                this.$router.push({path: 'completePayment', query: {id: data.id}})
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            returnBack() {
                this.$router.push({path: 'storeList'})
            }
        },
    };
</script>

<style scoped lang="scss">
    @import "@/assets/css/common.scss";
    @import "@/assets/css/element.scss";

    .billingDetailClass {
        .centerDetail {
            max-width: 1166px;
            margin: auto;
        }
        .returnBtn {
            color: #54ab8e;
            margin-bottom: 31px;
            cursor: pointer;
            span {
                margin-left: 10px;
            }
        }
        .storeName {
            color: #312f2e;
            font-size: 20px;
            margin-bottom: 30px;
            .line {
                color: #312f2e;
                padding: 0 10px;
            }
        }
        .orangeFont {
            color: $orange-font;
            font-size: 16px;
        }
        .toPay {
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #54AB8E;
            font-size: 18px;
            margin-left: 10px;
            font-weight: 400;
            color: $green;
            padding: 5px 20px;
            cursor: pointer;
        }
        .titleBorder {
            height: 64px;
            border: 1px solid #E2D6CB;
            border-bottom: none;
            font-size: 20px;
            padding-left: 30px;
            color: #312F2E;
            line-height: 64px;
        }
        .paginationBorder {
            height: 64px;
            border: 1px solid #E2D6CB;
            border-top: none;
            font-size: 20px;
            padding-left: 30px;
            color: #312F2E;
            line-height: 64px;
        }
    }
</style>
<!--修改element 中的样式-->
<style lang="scss">

</style>
